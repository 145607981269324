// extracted by mini-css-extract-plugin
export var button = "loxoJobs-module--button--8760a";
export var companyName = "loxoJobs-module--companyName--719b6";
export var jobContainer = "loxoJobs-module--jobContainer--c6904";
export var jobEntrance = "loxoJobs-module--jobEntrance--4c62c";
export var jobField = "loxoJobs-module--jobField--5a4fd";
export var jobFieldLeft = "loxoJobs-module--jobFieldLeft--06568";
export var jobFieldMiddle = "loxoJobs-module--jobFieldMiddle--223d7";
export var jobFieldRight = "loxoJobs-module--jobFieldRight--57f3f";
export var jobTitle = "loxoJobs-module--jobTitle--38dda";
export var jobTitleWrapper = "loxoJobs-module--jobTitleWrapper--e15e3";
export var svg = "loxoJobs-module--svg--d279c";
export var text = "loxoJobs-module--text--c7ba6";
export var title = "loxoJobs-module--title--a8aef";
export var wrapper = "loxoJobs-module--wrapper--0a04b";