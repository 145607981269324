import * as styles from "./pageHero.module.scss";
import React, {createRef, useEffect} from "react";
import LoxoJobs from "../loxoJobs/loxoJobs";
import lottie from 'lottie-web';
import animationData from "../../asset-animation/pageHeroAnimation.json";

export default function PageHero(props: PagesHeroProps) {
    const animationContainer = createRef<any>();

    useEffect(() => {
        const animation = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animationData
        });
        return () => animation.destroy();
    }, []);

    return <div className={styles.wrapper}>
        <div className={styles.card}>
            <div className={styles.backgroundAnimation} ref={animationContainer}/>
            <span className={styles.sectionTitle}>
                {props.sectionTitle}
            </span>
            <span className={styles.title}>
                {props.header}
            </span>
            <span style={{maxWidth: props.textMaxWidth}} className={styles.subtext}>
                {props.text}
            </span>
        </div>
        {props.loxoJobs !== undefined
            ? <LoxoJobs loxoJobs={props.loxoJobs} displayTitle={props.displayLoxoJobsTitle}/>
            : null}
    </div>
}

interface PagesHeroProps {
    sectionTitle: string,
    header: string,
    text: string,
    textMaxWidth?: string,
    loxoJobs?: any,
    displayLoxoJobsTitle?: boolean;
}
