import React from "react";
import * as styles from "./loxoJobs.module.scss";
import LogoSVG from "../../svg/logoRounded.svg";
import {Col, Row} from "react-bootstrap";
import PinSVG from "../../svg/jobs/pin.svg";
import CoinSVG from "../../svg/jobs/coin.svg";
import SunSVG from "../../svg/jobs/sun.svg";
import {LinkButton} from "../linkButton/linkButton";

export default function LoxoJobs(props: LoxoJobsProps) {
    return <div className={styles.wrapper}>
        {props.displayTitle ?
            <div className={styles.title}>Latest roles and positions our clients are looking for</div> : null}
        <Row style={{justifyContent: 'center'}}>
            {props.loxoJobs.nodes.map((job, index) => <Col md={12} key={index} className={styles.jobContainer}>
                    <Row className={styles.jobEntrance}>
                        <Col sm={2} xs={3} md={2}>
                            <LogoSVG/>
                        </Col>
                        <Col sm={10} xs={9} md={10} className={styles.jobTitleWrapper}>
                            <div className={styles.jobTitle}>{job.title}</div>
                            <div className={styles.companyName}>{job.company.name}</div>
                        </Col>
                    </Row>
                    <Row style={{justifyContent: 'center'}}>
                        <Col md={12} lg={3} className={styles.jobFieldLeft}>
                            <PinSVG className={styles.svg}/>
                            <div className={styles.text}>
                                {job.city && job.country_code
                                    ? `${job.city}, ${job.country_code}`
                                    : `Anywhere`
                                }
                            </div>
                        </Col>
                        <Col md={12} lg={3} className={styles.jobFieldMiddle}>
                            <CoinSVG className={styles.svg}/>
                            <div className={styles.text}>
                                {job.salary
                                    ? `£${job.salary}`
                                    : `Undisclosed`}</div>
                        </Col>
                        <Col md={12} lg={3} className={styles.jobFieldRight}>
                            <SunSVG className={styles.svg}/>
                            <div className={styles.text}>{job.job_type.name}</div>
                        </Col>
                    </Row>
                    <div className={styles.button}>
                        <LinkButton to={job.public_url}>
                            About this role
                        </LinkButton>
                    </div>
                </Col>
            )}
        </Row>
    </div>
}

interface LoxoJobsProps {
    loxoJobs: any,
    displayTitle?: boolean;
}
