import Layout from "../components/layout/layout";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import PageHero from "../components/pageHero/pageHero";
import JSONData from "../../content/insightPageHeroData.json";
import React from "react";
import PostsList from "../components/postsList/postsList";
import {Helmet} from "react-helmet";


export default function InsightsPage() {
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <html lang="en" />
            <title>Insights - Recoded Recruitment</title>
        </Helmet>
        <Layout
            header={<Header transparent={true}/>}
            footer={<Footer/>}
        >
            <PageHero textMaxWidth={"810px"} text={JSONData.text} sectionTitle={JSONData.sectionTitle}
                      header={JSONData.header}/>
            <PostsList/>
        </Layout>
    </>
}
